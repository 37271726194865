var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "mx-auto",
                  attrs: { "min-height": "120", outlined: "" },
                },
                [
                  _c("h2", [_vm._v("Komfortbewirtschaftung")]),
                  _c("v-simple-table", {
                    key: "priceKey",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [_vm._v("Vertragsart")]),
                                _c(
                                  "td",
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.alleVertragsarten,
                                        "item-text": "Vertrag",
                                        "single-line": "",
                                      },
                                      model: {
                                        value: _vm.localVertragsart,
                                        callback: function ($$v) {
                                          _vm.localVertragsart = $$v
                                        },
                                        expression: "localVertragsart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Produkt (Base)")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.produkte.base) +
                                      " - " +
                                      _vm._s(_vm.getPriceBase) +
                                      " €/MWh "
                                  ),
                                  _c("small", [
                                    _vm._v(
                                      "(Stand " + _vm._s(_vm.getDateBase) + ")"
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Produkt (Peak)")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.produkte.base) +
                                      " - " +
                                      _vm._s(_vm.getPricePeak) +
                                      " €/MWh "
                                  ),
                                  _c("small", [
                                    _vm._v(
                                      "(Stand " + _vm._s(_vm.getDatePeak) + ")"
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "12", md: "4" } }),
          _c("v-col", { attrs: { cols: "12", md: "4" } }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "mx-auto",
                  attrs: { "min-height": "120", outlined: "" },
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.loading
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "center",
                                width: "100%",
                              },
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "" },
                              }),
                            ],
                            1
                          )
                        : _c("v-simple-table", {
                            attrs: { "fixed-header": "", dense: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("thead", [
                                      _c(
                                        "tr",
                                        _vm._l(
                                          _vm.tableHeaders,
                                          function (header) {
                                            return _c(
                                              "th",
                                              {
                                                key: header.value,
                                                staticClass: "text-left",
                                              },
                                              [
                                                header.withCheckbox
                                                  ? [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          label: header.text,
                                                          value: header.text,
                                                        },
                                                        model: {
                                                          value: _vm.auswahl,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.auswahl = $$v
                                                          },
                                                          expression: "auswahl",
                                                        },
                                                      }),
                                                    ]
                                                  : [
                                                      _vm._v(
                                                        _vm._s(header.text)
                                                      ),
                                                    ],
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(_vm.tableItems, function (item) {
                                        return _c(
                                          "tr",
                                          { key: item.vertraege },
                                          _vm._l(item, function (value, key) {
                                            return _c("td", { key: key }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.translateState(value)
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          }),
                                          0
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.sendOrder },
                        },
                        [_vm._v("Jetzt bestellen")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }