<template>
    <div>
        <v-row>
            <v-col cols="12" md="4">
                <v-card class="mx-auto" min-height="120" outlined>
                    <h2>Komfortbewirtschaftung</h2>
                    <v-simple-table  key="priceKey">
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td>Vertragsart</td>
                                    <td>
                                        <v-select
                                            v-model="localVertragsart"
                                            :items="alleVertragsarten"
                                            item-text="Vertrag"
                                            single-line
                                        ></v-select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Produkt (Base)</td>
                                    <td>
                                        {{produkte.base}}
                                        - {{ getPriceBase }} €/MWh
                                        <small>(Stand {{ getDateBase }})</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Produkt (Peak)</td>
                                    <td>
                                        {{produkte.base}}
                                        - {{ getPricePeak }} €/MWh
                                        <small>(Stand {{ getDatePeak }})</small>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col cols="12" md="4"> </v-col>
            <v-col cols="12" md="4"> </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-card class="mx-auto" min-height="120" outlined>
                    <v-card-text>
                        <div v-if="loading" style="text-align: center; width: 100%;">
                            <v-progress-circular indeterminate></v-progress-circular>
                        </div>
                        <v-simple-table v-else fixed-header dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th
                                            v-for="header in tableHeaders"
                                            :key="header.value"
                                            class="text-left"
                                        >
                                            <template v-if="header.withCheckbox"
                                                ><v-checkbox
                                                    :label="header.text"
                                                    :value="header.text"
                                                    v-model="auswahl"
                                                ></v-checkbox
                                            ></template>
                                            <template v-else>{{
                                                header.text
                                            }}</template>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in tableItems"
                                        :key="item.vertraege"
                                    >
                                        <td
                                            v-for="(value, key) in item"
                                            :key="key"
                                        >
                                            {{ translateState(value) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" v-on:click="sendOrder"
                            >Jetzt bestellen</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <!--<v-divider></v-divider>
    <v-row>
    <pre>{{
        JSON.stringify($store.state.contract.aktuellerVertrag, null, 2)
        }}</pre>
      </v-row>-->
    </div>
</template>
  
<script>
import { functions } from "@/plugins/firebase"
import { httpsCallable } from "firebase/functions"
import { mapState, mapMutations } from "vuex"

export default {
    components: {},
    data() {
        return {
            tableKey: 0,
            priceKey: 0,
            orderSelected: false,
            selectedTrancheName: "",
            auswahl: [],
            loading: false,
        };
    },
    methods: {
        ...mapMutations("comfortcontracts", ["set_aktuelle_vertragsart"]),
        updateVertragsart(value) {
            this.set_aktuelle_vertragsart(value);
        },
        translateState(state) {
            let returnval = "";
            switch (state) {
                case "open":
                    returnval = "Offen";
                    break;
                case "closed":
                    returnval = "Geschlossen";
                    break;
                case null:
                    returnval = "-";
                    break;
                default:
                    returnval = state;
                    break;
            }
            return returnval;
        },
        sendOrder() {
            const auswahlArray = this.auswahl;
            const tranchennamen = Object.keys(
                this.trancheninfos
            );
            const userdata = loginNew.getProfile();
            for (const t_name of tranchennamen) {
                for (const tranche of auswahlArray) {
                    const strukturkonstate = parseFloat(
                        parseFloat(
                            (
                                (parseFloat(
                                    this.trancheninfos[t_name].StrukturkonstanteinEuro
                                ) /
                                    parseFloat(
                                        this.trancheninfos[t_name].TrancheMengeinkWh
                                    )) * 1000
                            ).toFixed(3)
                        )
                    );
                    const endpreis_struct={
                        base:
                            parseFloat(this.trancheninfos[t_name].FaktorBase) *
                            parseFloat(this.preise.base.price),
                        peak:
                            parseFloat(this.trancheninfos[t_name].FaktorPeak) *
                            parseFloat(this.preise.peak.price),
                        struct: strukturkonstate
                    }
                    const endprice = endpreis_struct["base"] + endpreis_struct["peak"] + endpreis_struct["struct"];
                    const cost=parseInt(this.trancheninfos[t_name].TrancheMengeinkWh) * endprice;
                    const faktorBase=this.trancheninfos[t_name]["FaktorBase"];
                    const faktorPeak=this.trancheninfos[t_name]["FaktorPeak"];
                    let orderdata = {
                        ordertype:"Komfort",
                        cost: cost,
                        volume: parseInt(this.trancheninfos[t_name].TrancheMengeinkWh),
                        fixed: 0,
                        orderdatetime: "",
                        orderedby: userdata.sub,
                        orderedbyName: `${userdata.name} ${userdata.family_name}`,
                        pricing: {
                            baseprice:
                                this.preise.base
                                    .price,
                            peakprice:
                                this.preise.peak
                                    .price,
                            structprice: strukturkonstate,
                            price: endprice,
                            pricedate_base:
                                this.preise.base
                                    .product_ts,
                            pricedate_peak:
                                this.preise.peak
                                    .product_ts,
                            price_products:
                                this.produkte,
                        },
                        faktorBase: faktorBase,
                        faktorPeak: faktorPeak
                    };

                    const order = {
                        tranchenzeitraum: t_name,
                        tranchennummer: tranche,
                        orderdaten: orderdata,
                    };
                    this.order(order);
                }
            }
        },
        async order(orderdata) {
            const orderTranche = httpsCallable(functions, "orderTranche")
            this.loading = true
            console.log("order",orderdata);
            try {
                const { data } = await orderTranche(orderdata)
                switch (data.code) {
                    case -1:
                        this.$store.commit(
                            "showSnackbar",
                            {
                                message: data.msg,
                                status: "error"
                            },
                            { root: true }
                        )
                        this.dialogVisible = false
                        break
                    case 0:
                        this.$store.commit(
                            "showSnackbar",
                            {
                                message: data.msg,
                                status: "success"
                            },
                            { root: true }
                        )
                        this.$store.state.contract.reloadOrderview=true

                        this.dialogVisible = false
                        break
                    default:
                        break
                }
            } catch (err) {
                this.$store.commit(
                    "showSnackbar",
                    {
                        message: "Es ist ein Fehler aufgetreten bei dem Versuch die Bestellung abzusenden.",
                        status: "error"
                    },
                    { root: true }
                )
                console.error(err)
            }
            this.loading = false
        },
    },
    computed: {
        ...mapState("comfortcontracts", ["preise","tabellen","trancheninfos","produkte","aktuelleVertragsart","alleVertragsarten"]),
        localVertragsart: {
            get() {
                return this.aktuelleVertragsart;
            },
            set(value) {
                this.updateVertragsart(value);
            }
        },
        tableItems() {
            console.log("🚀 ~ tableItems ~ tableItems:", this.tabellen.Data)
            return this.tabellen.Data;
        },
        tableHeaders() {
            return this.tabellen.Header;
        },
        getPriceBase() {
            if (
                this.preise.base &&
                this.preise.base.price
            ) {
                return this.preise.base.price;
            } else {
                return 0;
            }
        },
        getPricePeak() {
            if (
                this.preise.peak &&
                this.preise.peak.price
            ) {
                return this.preise.peak.price;
            } else {
                return 0;
            }
        },
        getDateBase() {
            if (
                this.preise.base &&
                this.preise.base.product_ts
            ) {
                return new Date(
                    this.preise.base.product_ts
                ).toLocaleString("de-DE");
            } else {
                return 0;
            }
        },
        getDatePeak() {
            if (
                this.preise.peak &&
                this.preise.peak.price
            ) {
                return new Date(
                    this.preise.peak.product_ts
                ).toLocaleString("de-DE");
            } else {
                return 0;
            }
        },
    },
    watch: {
        "$store.state.comfortcontracts.aktuelleVertragsart": {
            handler: function (newval) {
                if (!newval) {
                    return;
                }
                console.log("aktuellerVertragsname gesetzt");
                this.loading=true
                this.$store
                    .dispatch("comfortcontracts/setAktuellerVertragsname", {
                        vertragsname: newval,
                    })
                    .then(() => {
                        this.loading=false
                        console.log("aktuellerVertragsname abgeschlossen");
                    });
            },
            immediate: true,
        },
        "$store.state.comfortcontracts.tabellen.Header": {
            handler: function (newval) {
                if (!newval) {
                    return;
                }
                this.tableKey++;
            },
            immediate: true,
        },
        "$store.state.comfortcontracts.produkte.base": {
            handler: function (newval) {
                if (!newval) {
                    return;
                }
                this.priceKey++;
            },
            immediate: true,
        },
        "$store.state.comfortcontracts.produkte.peak": {
            handler: function (newval) {
                if (!newval) {
                    return;
                }
                this.priceKey++;
            },
            immediate: true,
        },
        loading() {
            console.log("🚀 ~ tableItems ~ loading:", this.loading)
        }
    },
    async mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login");
            return;
        }
        this.$store.dispatch("comfortcontracts/holeVertragsarten").then(() => {
            console.log("aktuellerVertragsname gesetzt - mounted");
        });
    },
};
</script>
  
  <style>
</style>